body {
  margin: 0;
}

$isPlaying-bg: #7cb342;
$host-player-color: #3949ab;
$opponent-player-color: #00897b;

:root {
  --font-size: 16px;
}

/* Grid */
main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: calc(100vh - 7rem);
  padding: 1rem 0;
}

article {
  padding: 0;
  overflow: hidden;
}

article div {
  padding: 1rem;
}

@media (min-width: 576px) {
  body > main {
    padding: 1.25rem 0;
  }

  article div {
    padding: 1.25rem;
  }
}

@media (min-width: 768px) {
  body > main {
    padding: 1.5rem 0;
  }

  article div {
    padding: 1.5rem;
  }
}

@media (min-width: 992px) {
  body > main {
    padding: 1.75rem 0;
  }

  article div {
    padding: 1.75rem;
  }
}

@media (min-width: 1200px) {
  body > main {
    padding: 2rem 0;
  }

  article div {
    padding: 2rem;
  }
}

@import "@picocss/pico/scss/pico";
