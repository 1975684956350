@import "../../index.scss";

.modal {
  position: fixed;
  z-index: 10;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 0 !important;
}

/* Modal Content/Box */
.modal-box {
  background-color: var(--progress-background-color);
  margin: 0 auto;
  top: 50%;
  transform: translateY(-50%);
  border: 2px solid var(--primary);
  width: 80%;
  position: relative;
  max-height: 90vh;
  padding: 0 !important;

  > img {
    position: absolute;
    margin-top: -32px;
    margin-left: calc(50% - 32px);
    border-radius: 32px;
    border: 3px solid var(--primary);
    background: var(--primary);
    z-index: 1;
  }

  .modal-inner {
    overflow: auto;
    padding: 2rem;
    max-height: calc(90vh - 4px);
  }

  .modal-content {
    padding: 0 !important;

    > div {
      flex: 1;
      word-break: break-all;
    }
    > .modal-actions {
      display: flex;
      margin-top: 2rem;
      > button {
        margin: 0;
      }
      > button:nth-of-type(1) {
        margin-right: 0.5rem;
      }
    }
  }
}
