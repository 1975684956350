@import "../../index.scss";

/* Avatar selector */
.loginForm div:nth-of-type(2) {
  display: none;
}

.avatar-selector {
  > img {
    margin: 0 0.4rem 0.4rem 0;
    border-radius: 32px;
    border-width: 3px;
    border-style: solid;
    border-color: transparent;

    cursor: pointer;
    &.selected {
      border-color: $primary-700;
      &:focus-visible {
        outline: 2px solid var(--color);
      }
    }
    &:focus-visible {
      outline: 2px solid var(--color);
    }
  }

  padding: 0;
}

@media (min-width: 992px) {
  .loginForm > div:nth-of-type(2) {
    display: block;
  }
  button.btn-select-avatar {
    display: none;
  }
}
