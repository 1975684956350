@import "../../index.scss";

nav {
  background-color: $grey-900;
  a {
    color: $white;
  }
  overflow: hidden;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 1;

  a.logo {
    padding: 0.4rem;
    font-weight: 600;
    > img {
      margin-right: .6rem;
    }
  }
}

.profile {
  width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  align-self: center;
  white-space: nowrap;
  text-align: right;
  margin-right: 1rem;
  color: $white;

  img {
    margin: 0 0.5rem;
  }
}
