@import "../../../index.scss";

.game > .game__quadrant {
  border: 3px solid var(--background-color);
  min-height: 50px;
  min-width: 50px;
  position: relative;

  .card-content {
    position: absolute;
    height: 100%;
    width: 100%;

    transition: transform 1s;
    transform-style: preserve-3d;

    transform: rotateY(0deg);
    transition: transform 0.5s;

    &.flipped {
      transform: rotateY(180deg);
      transition: transform 0.5s;
    }

    .cover,
    .card-image {
      width: 100%;
      height: 100%;
      position: absolute;
      backface-visibility: hidden;
    }

    .cover {
      background: linear-gradient(
        45deg,
        $host-player-color 0%,
        $opponent-player-color 100%
      );
      box-shadow: inset 0.15rem 0.15rem $primary-700;
      &.solved {
        box-shadow: none;
      }
    }

    .card-image {
      box-shadow: inset 0.15rem 0.15rem $grey-900;
      transform: rotateY(180deg);

      > img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        position: relative;
        &.solved {
          opacity: 0.5;
        }
      }
    }
  }

  &.selectable {
    &:focus-visible {
      outline: 2px solid var(--color);
      z-index: 1;
    }
    &:hover,
    &:focus,
    &:focus-visible {
      > .card-content > .cover {
        cursor: pointer;
        background: linear-gradient(
          -45deg,
          $host-player-color 0%,
          $opponent-player-color 100%
        );
        box-shadow: inset 0.15rem 0.15rem $primary-400;
      }
    }
  }
}
